import React from 'react';
import {Container, Row} from 'react-bootstrap';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import ArcadesBackground from '../assets/retroarcade1.png';
import CardsBackground from '../assets/giftCards.png';
import EventsBackground from '../assets/event.png';

class AppListCards extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isScrolling: false,
        }
    }
    componentDidMount = ()=> {
        window.addEventListener("scroll", this.onScroll);
      }
      
      componentWillUnmount = () =>{
        window.removeEventListener("scroll", this.onScroll);
      }
    
      onScroll = () => {
        this.setState({ isScrolling: true });
        if(window.pageYOffset === 0){
            this.setState({ isScrolling: false });
        }
      };
    
    render(){
        return(
            <Container className="list-cards-container my-5">
                <CardDeck>
                    <Card className="text-white shadow app-card" onClick={()=>{
                            window.open('https://geekmaroc.com/agence-devenementiel-gaming-maroc/location-bornes-arcades/');
                        }}
                        style={{
                            background: 'black'}}
                        >
                        <Card.Img alt="Card image" src={ArcadesBackground}/>
                        <Card.ImgOverlay className="card-content px-0">
                            <Card.Title className="app-card-title p-2 m-0">Bornes Arcades</Card.Title>
                            <Card.Text className="card-text-background card-text p-2 text-justify">
                                Notre équipe de passionnés fabrique dans ses ateliers au Maroc votre borne
                                d'arcade sur-mesure personnalisable pour les particuliers et les entreprises.
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="text-white shadow app-card"  onClick={()=>{
                            window.open('https://geekmaroc.shop/');
                        }}
                        style={{
                            background: 'black'}}>
                        <Card.Img alt="Card image" src={CardsBackground}/>
                        <Card.ImgOverlay className="card-content px-0">
                            <Card.Title className="app-card-title p-2 m-0">Cartes prépayées / Gadgets</Card.Title>
                            <Card.Text className="card-text-background card-text p-2 text-justify">
                                La première agence marocaine spécialisée dans la vente des jeux et des produits
                                digitaux (cartes dématérialisés) au Maroc, ainsi que les gadgets GEEK.
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                    <Card className="text-white shadow app-card" onClick={()=>{
                            window.open('http://agency.geekmaroc.com/');
                        }}
                        >
                        <Card.Img alt="Card image" src={EventsBackground}/>
                        <Card.ImgOverlay className="card-content px-0">
                            <Card.Title className="app-card-title p-2 m-0">Événementiels</Card.Title>
                            <Card.Text className="card-text-background card-text p-2 text-justify">
                                GEEK Maroc accompagne les marques et les institutions dans leurs projets Esport
                                et les aidons à toucher cet immense public aguerri aux nouveaux usages de cet écosystème.

                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </CardDeck>
                <Row>
                    <div className='text-center icon-scroll'  hidden={this.state.isScrolling}></div>
                </Row>
            </Container>
        );
    }
}
export default AppListCards;