import React from 'react';
import {Container, Row, Col, CardDeck, Card} from 'react-bootstrap';

export default function AppMaps(){
    return(
        <Container className="text-center my-5">
            <Row>
                <Col>
                    <h1 className="app-title text-center my-3">Rendez-Nous Visite</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CardDeck className="my-3">
                       <Card>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3713.9902776633176!2d-7.633524212903771!3d33.60129687106355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d28b6a79945b%3A0x4266759c7cfb4711!2sGEEK%20Maroc%20-%20Casablanca!5e0!3m2!1sfr!2sma!4v1608480196665!5m2!1sfr!2sma"
                                title="Casablanca"
                                width="100%"
                                height="450"
                                frameBorder="0"
                                style={{ border:0}}
                                aria-hidden="false"
                                tabIndex="0">
                            </iframe>
                       </Card>
                        <Card>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3441.3035314031617!2d-9.535508685390353!3d30.399128981754618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdb3c8301930a8b5%3A0x6c7f3e50ee43f4c1!2sGEEK%20Maroc%20-%20Agadir!5e0!3m2!1sfr!2sma!4v1608480470207!5m2!1sfr!2sma" 
                                    title="Agadir"
                                    width="100%"
                                    height="450"
                                    frameBorder="0"
                                    style={{border:0}} 
                                    aria-hidden="false" 
                                    tabIndex="0">
                            </iframe>
                        </Card>
                    </CardDeck>
                </Col>
            </Row>
        </Container>
    );
  
}