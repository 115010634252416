import React, { useEffect, useState } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import refsData from '../assets/refsData.json';
import axios from 'axios';
export default function AppRef(){
    const [data, setData] = useState([]);
    
    useEffect(() => {
        axios.get('refsData.json')
        .then(res => {
            setData(res.data);
        })
        .catch(err => {
            console.log('error')
            console.log(err)
        });
    }, []);
    let settings = {
        autoplay: true,
        autoplaySpeed: 0,
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: false,
        arrows: false,
        centerPadding: "0px",
        centerMode: false,
        draggable: false,
        ease: 'ease-in-out',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };
    return(
        <Container className="slider-container my-5 p-0">
            <Row>
                <Col>
                    <h1 className="app-title text-center mb-3">Nos  Références</h1>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <Slider {...settings}>
                         {data.map((elem, index) => <div className="m-0 p-0 text-center" key={index}>
                            <img src={"ref-img/"+elem.imgPath} alt={elem.name} className="img-slider"/>
                        </div>)}
                    </Slider>
                </Col>
            </Row>

        </Container>


    );
}