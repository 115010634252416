import React from "react";
import AppB2B from './AppB2B';
import AppContact from './AppContact';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container"
import Nav from 'react-bootstrap/Nav';
import logo from '../logo.svg';
import FacebookIcon from '../Facebook.svg';
import TwitchIcon from '../twitch.svg';
import YoutubeIcon from '../Youtube.svg';
import InstagramIcon from '../Instagram.svg';
class AppNavBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            show: false,
            showContact: false,
        }
    }
    handleClose = () => {
        this.setState({show: false});
    }
    handleShow = () => {
        this.setState({show: true});
    }

    handleCloseContact = () => {
        this.setState({showContact: false});
    }
    handleShowContact = () => {
        this.setState({showContact: true});
    }
    render(){
        return(
            <Container fluid className="justify-content-center p-0 my-4">
                <Navbar  variant="dark"  sticky="top">
                    <Nav className="m-auto ">
                                <Nav.Link className="d-none d-md-block" href="https://www.twitch.tv/geekmaroctv" target="_blank" rel="noreferrer" ><img className="app-link-img mr-4"
                                    src = {TwitchIcon}
                                    width="24"
                                    height="24"
                                    alt="twitchIcon"
                                   />
                                </Nav.Link>
                          
                                <Nav.Link className="d-none d-md-block" href="https://www.facebook.com/geekmaroc.agency/" target="_blank" rel="noreferrer"><img className="app-link-img mr-4"
                                    src = {FacebookIcon}
                                    width="24"
                                    height="24"
                                    alt="facebookIcon"
                                    />
                                </Nav.Link>
                            
                                <Nav.Link className="d-none d-md-block" href="https://www.instagram.com/geekmaroc.agency" target="_blank" rel="noreferrer"><img className="app-link-img mr-4"
                                    src = {InstagramIcon}
                                    width="24"
                                    height="24"
                                    alt="instagramIcon"
                                    />
                                </Nav.Link>
                         
                                <Nav.Link className="d-none d-md-block" href="https://www.youtube.com/channel/UCVU1pdZPzKop9GWCEJKJ_RA" target="_blank" rel="noreferrer"><img className="app-link-img ytb mr-4"
                                    src = {YoutubeIcon}
                                    width="34"
                                    height="24"
                                    alt="youtubeIcon"
                                    />
                                </Nav.Link>
                    
                    </Nav>
                    <Navbar.Brand className="text-center mx-2">
                        <img src={logo} 
                            alt="logo-geekMaroc" 
                            width="164"
                            height="126"
                            className="d-inline-block align-top app-logo"
                        />
                    </Navbar.Brand>
                        <Nav className="m-auto">
                            <Nav.Link  onClick={() => this.handleShow()} className="app-nav-link d-none d-md-block">B2B</Nav.Link>
                            <Nav.Link onClick={() => this.handleShowContact()}  className="app-nav-link d-none d-md-block" >Nous contacter</Nav.Link>
                        </Nav>
                </Navbar>
                <AppB2B show={this.state.show} close={this.handleClose}></AppB2B>
                <AppContact show={this.state.showContact} close={this.handleCloseContact}></AppContact>
            </Container>
        );
    }     
}
export default AppNavBar;