import React from 'react';
import axios from 'axios';
import {Modal, Form, Button, Col} from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default class AppB2B extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name : "",
            email : "",
            objet : "",
            message : "",
            organisme: "",
            telephone: "",
            isValide: false,
            error: false,
            msgSent: 0,
            btnDisabled: true
        } 
    }
    handleSubmit = (event) =>{
        event.preventDefault();
        console.log("submit clicked");
        const form = event.currentTarget;
        if(this.state.isValide !== true || form.checkValidity() === false){
            this.setState({error: true});
        }else{
            axios.post(`https://geekmaroc.com/mailfichier11/sendMail.php`,
                        {
                            name: this.state.name,
                            email: this.state.email,
                            subject: this.state.objet,
                            message: this.state.message,
                            organisme: this.state.organisme,
                            telephone: this.state.telephone,
                        }
                )
                .then(res => {
                    if(res.data === 1){
                
                        this.setState({
                            msgSent: 2,
                        });
                        
                    }else{
                        console.log(this.state.msgSent)
                        this.setState({
                            msgSent: 3,
                        });
                    }
                    console.log(res);
                })
                .catch(error => {
                    this.setState({
                        msgSent: 3,
                    });
                });

            this.setState({
                msgSent: 1
            }
            );
        }
    }
    validateEntries = () => {
        console.log(this.state);
        if(this.state.name !== "" &&  this.state.email !== "" &&
            this.state.objet !== "" &&  this.state.message !== "" &&
            this.state.organisme !== "" && this.state.telephone !== "" && this.state.telephone.length >= 6){
            this.setState({
                isValide: true,
                btnDisabled: false
            });
        }else{
            this.setState({isValide: false});
        }
    }


    handleChange = (event) =>{
        this.setState({error: false});
        let targetName = event.target.name;
        let targetValue = event.target.value;
        switch(targetName){
            case 'name':
                this.setState({name : targetValue});
                break;
            case 'email':
                this.setState({email : targetValue});
                break;
            case 'objet':
                this.setState({objet : targetValue});
                break;
            case 'message':
                this.setState({message : targetValue});
                break;
            case 'organisme':
                this.setState({organisme: targetValue});
                break;
            
            default:
                break;
        }
        console.log(this.state);
        this.validateEntries();
    }
    resendMessage = () => {
        this.setState({
            msgSent: 0
        });
    }
    closeDialog = (event) => {
        this.props.close();
        this.setState({
            name: "",
            email: "",
            objet: "",
            message: "",
            organisme: "",
            telephone: "",
            isValide: false,
            msgSent: 0,
        }); 
    }

    render(){
        if(this.state.msgSent === 0){
            return (
                <>
                <Modal className="modalB2B" 
                    show={this.props.show}
                    onHide={this.props.close}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                    <Modal.Title>B2B Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="modal-paragraph">
                            Demander plus d'information, ou demander votre devis.
                        </p>
                        <Form noValidate validated={this.state.isValide}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Nom Complet</Form.Label>
                                    <Form.Control required name="name" type="text" 
                                        value={this.state.name}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridName">
                                    <Form.Label>Organisme</Form.Label>
                                    <Form.Control required name="organisme" type="text" 
                                        value={this.state.organisme}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}  controlId="formGridAddressEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control  required name="email" type="email" 
                                        value={this.state.email}
                                        onChange={this.handleChange.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Merci de fournir une adresse mail valide.
                                    </Form.Control.Feedback>   
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridPhone">
                                    <Form.Label>Téléphone</Form.Label>
                                    <PhoneInput
                                    inputProps={{
                                        name: 'telephone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                        country={'ma'}
                                        value={this.state.telephone}
                                        onChange={(telephone) => {
                                                this.setState({ telephone: telephone })
                                                this.validateEntries();
                                            }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Merci de fournir un numero de téléphone valide.
                                    </Form.Control.Feedback>   
                                </Form.Group>
                            </Form.Row>
                            
                           
                            <Form.Group controlId="formGridSubject">
                                <Form.Label>Objet</Form.Label>
                                <Form.Control required name="objet" type="text" 
                                    value={this.state.objet}
                                    onChange={this.handleChange.bind(this)}
                                />
                            </Form.Group>
                               
                            <Form.Group controlId="Form.ControlMessage">
                                <Form.Label>Votre message</Form.Label>
                                <Form.Control required name="message" as="textarea" rows={3}
                                    value={this.state.message}
                                    onChange={this.handleChange.bind(this)}
                                />                    
                            </Form.Group>
                        </Form>
                    </Modal.Body>    
                    <Modal.Footer>
                        <Button className="btn-send" variant="primary" type="submit" disabled={this.state.btnDisabled} onClick={this.handleSubmit}>
                            Envoyer
                        </Button>
                        <Button  className="btn-cancel" variant="dark" onClick={this.closeDialog}>
                            Annuler
                        </Button>
                    </Modal.Footer>
                </Modal>
                </>
            );
        }else if(this.state.msgSent === 1){
            return (
                <>
                <Modal className="modalB2B" 
                    show={this.props.show}
                    onHide={this.props.close}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                    <Modal.Title>B2B Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Envoi encore ...
                        </p>
                    </Modal.Body>    
                </Modal>
                </>
            );
        }else if(this.state.msgSent === 2){
            return (
                <>
                <Modal className="modalB2B" 
                    show={this.props.show}
                    onHide={this.props.close}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header>
                    <Modal.Title>B2B Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Merci, votre message a été bien envoyé.
                        </p>
                    </Modal.Body>    
                    <Modal.Footer>
                        <Button className="btn-fermer" variant="success" onClick={this.closeDialog}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>
                </>
            );
        }else{
            return (
                <>
                <Modal className="modalB2B" 
                    show={this.props.show}
                    onHide={this.props.close}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title>B2B Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Impossible d'envoyer le message. Veuillez vérifier votre connexion réseau et réessayer.
                        </p>
                    </Modal.Body>    
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.resendMessage}>
                            Réssayer
                        </Button>
                    </Modal.Footer>
                </Modal>
                </>
            );
        }
    }
    
}