import React from 'react';
import AppListCards from './AppListCards';
import AppRef from "./AppRef";
import AppMaps from "./AppMaps";
import {Container, Row, Col} from 'react-bootstrap';

function AppBody(){
    return(
        <Container className="app-body-container my-5">
            <div>
                <Row>
                    <Col className="text-center">
                        <h1 className="welcome mt-5">Bienvenue Chez GEEK MAROC</h1>
                        <h3 className="sous-titre mt-2">
                            GEEK Maroc est la plus grande régie 100% marocaine spécialisée dans le Gaming/Esport et le divertissement GEEK.
                        </h3>
                    </Col>
                </Row>
            </div>
           <div>
                <Row>
                    <AppListCards></AppListCards>
                </Row>
           </div>
            <div>
                <Row>
                    <AppRef></AppRef>
                </Row>
            </div>
            <div>
                <Row>
                    <AppMaps></AppMaps>
                </Row>
            </div>
            
        </Container>
    );
}
export default AppBody;