import AppNavBar from './components/AppNavBar';
import AppBody from './components/AppBody';
import AppFooter from './components/AppFooter';
import Container from 'react-bootstrap/Container'


import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Container>
        <AppNavBar></AppNavBar>
        <AppBody></AppBody>
        <AppFooter></AppFooter>
      </Container>
    </div>
  );
}

export default App;
