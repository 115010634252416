import React from 'react';
import {useState} from 'react';
import AppB2B from './AppB2B';
import AppContact from './AppContact';
import FacebookIcon from '../Facebook.svg';
import TwitchIcon from '../twitch.svg';
import YoutubeIcon from '../Youtube.svg';
import InstagramIcon from '../Instagram.svg';
import {Container, Nav, Row} from 'react-bootstrap';
function AppFooter(props){
    const [show, setShow] = useState(false);
    const [showContact, setShowContact] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseContact = () => setShowContact(false);
    const handleShowContact = () => setShowContact(true);

    return(
        <Container >
            <Row className='my-4 mx-auto justify-content-center text-center d-md-none d-sm-block'>
                <Nav.Link style={{ textDecoration: 'none'}} className="app-nav-link mx-auto" onClick={() => handleShow()}>B2B</Nav.Link>
                <Nav.Link  style={{ textDecoration: 'none'}} className="app-nav-link mx-auto" onClick={() => handleShowContact()}>Nous contacter</Nav.Link>
            </Row>
            <Row className='my-3  justify-content-center text-center d-md-none d-xs-block '>
                    <a className=" mx-auto" href="https://www.twitch.tv/geekmaroctv" target="_blank" rel="noreferrer"><img className="app-link-img mr-4"
                        src = {TwitchIcon}
                        width="24"
                        height="24"
                        alt="twitchIcon"
                        />
                    </a>               
                            
                    <a className=" mx-auto" href="https://web.facebook.com/geeekmaroc" target="_blank" rel="noreferrer"><img className="app-link-img mr-4"
                        src = {FacebookIcon}
                        width="24"
                        height="24"
                        alt="facebookIcon"
                        />
                    </a>
                                
                    <a className=" mx-auto" href="https://www.instagram.com/geekmaroc/" target="_blank" rel="noreferrer"><img className="app-link-img mr-4"
                        src = {InstagramIcon}
                        width="24"
                        height="24"
                        alt="instagramIcon"
                        />
                    </a>
                  
                    <a className=" mx-auto" href="https://www.youtube.com/channel/UCVU1pdZPzKop9GWCEJKJ_RA" target="_blank" rel="noreferrer"><img className="app-link-img  mr-4"
                        src = {YoutubeIcon}
                        width="34"
                        height="24"
                        alt="youtubeIcon"
                        />
                    </a>
            </Row>
            <Row>
            <h5 className="text-center mx-auto text-white app-footer">
                Copyright © 2015 - {new Date().getFullYear()}. Tous les droits sont réservés geekmaroc.com - St.GEEKMAROC - Casablanca 
                GEEKMAROC SARL | RC 386971 | IF 24883074 | ICE 001823393000060
            </h5>
            </Row>

            <AppB2B show={show} close={handleClose}></AppB2B>
            <AppContact show={showContact} close={handleCloseContact}></AppContact>
        </Container>
    );
}
export default AppFooter;